import React, { useState, useEffect } from "react";
import { useBook } from "../../contexts/BookContext";

import styles from "./BookBanner.module.css";

import { ReviewBox } from "../ReviewBox/ReviewBox";
import { FieldsBox } from "../FieldsBox/FieldsBox";
import { EditionBox } from "../EditionBox/EditionBox";

import { Journal } from "../Journal/Journal";

import { Recommendations } from "../Recommendations/Recommendations";
import { BookInfoBox } from "../BookInfoBox/BookInfoBox";

export const BookBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const bookItem = useBook();

    const [isLoaded, setIsLoaded] = useState(false);
    
    useEffect(() => { 
        // checks to see if book data has loaded yet
        if (Object.keys(bookItem).length !== 0) {
            setIsLoaded(true);
        }
    }, [bookItem]);

    useEffect(() => { // scrolls to top on first render
        window.scroll(0, 0);
    }, [])

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER


    if (!isLoaded) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.rowOne}>
                    <BookInfoBox />
                    <EditionBox bookItem={bookItem} />
                </div>
                {bookItem.userbookID ?
                    <div className={styles.userbookInfo}>
                        <div className={styles.rowTwo}>
                            <FieldsBox bookItem={bookItem} />
                            <ReviewBox bookItem={bookItem} />
                        </div>

                        <div className={styles.rowThree}>
                            <Recommendations bookItem={bookItem} />
                        </div>

                        <div className={styles.rowFour}>
                            <Journal bookItem={bookItem} />
                        </div>
                    </div>
                    : 
                    null
                }
            </div>
        );
    }

}