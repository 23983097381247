import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import styles from "./SeriesWorkBook.module.css";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

export const SeriesWorkBook = (props) => {
    
    // props:
    //
    // bookItem - object containing book information
    // ----------------------------------------------------- STATE AND HOOKS

    const [isOpen, setIsOpen] = useState(false); //state of truncated review text block, open/close
    const [showReadMoreButton, setShowReadMoreButton] = useState(false);

    const [firstWordsOfTitle, setFirstWordsOfTitle] = useState("");
    const [lastWordOfTitle, setLastWordOfTitle] = useState("");

    let formattedFullDescripton = formatDescription();

    const bookPath = "/book/" + props.bookItem.bookID;
    const seriesNumString = props.bookItem.seriesNum + ". ";

    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            setShowReadMoreButton(ref.current.scrollHeight !== ref.current.clientHeight)
        }
    }, [ref])

    useEffect(()=>{
        if (props.bookItem && props.bookItem.title) {processTitle()}
    },[props.bookItem])


    // ----------------------------------------------------- PRE-RENDER

    function formatDescription() {
        let formattedDescripton = []
        let descriptionPeices = props.bookItem.description.split('\n'); //splits description string by newlines
        if (descriptionPeices.length === 1) { //no new lines
            return props.bookItem.description;
        } else {
            for (let i = 0; i < descriptionPeices.length; i++) {
                if (!descriptionPeices[i]) { //if empty it means there was a blank line
                    formattedDescripton.push(<div key={i} className={styles.spacer}></div>)
                } else {
                    formattedDescripton.push(<div key={i}>{descriptionPeices[i]}</div>) //adds string segment to array
                }
            }
            return (formattedDescripton);
        }
    }

    function processTitle() {
        // splits title into two peices and adds star to second to prevent weird line wrapping
        let words = props.bookItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "
        
        if (props.bookItem.favorited) {
            lastWord = [lastWord , <img key="star" className={styles.starIconFilled} alt="Favorite star" />];
        }
        else {
            lastWord = [lastWord];
        }
    
        setFirstWordsOfTitle(firstWords);
        setLastWordOfTitle(lastWord);
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <Cover bookItem = {props.bookItem} size={window.screen.width > 500 ? "L" : "M"} loc="seriesBook"/>
                <div className={styles.bookInfo}>
                    <Link to={bookPath} className={styles.link}>
                        <div >
                            <span className={styles.seriesNum}>{seriesNumString}</span>
                            <span className={styles.firstWordsOfTitle}>{firstWordsOfTitle}</span>
                            <span className={styles.lastWordOfTitle}>{lastWordOfTitle}</span>
                        </div>
                    </Link>
                    <div>
                    <Author authorsList={props.bookItem.authors} size={window.screen.width > 500 ? 1 : 0} link={true}/>
                        <div className={isOpen ? styles.bookDescriptionOpen : styles.bookDescriptionClosed} ref={ref}>{formattedFullDescripton}</div>
                        {showReadMoreButton ? (
                            <button className={styles.readMoreOrLessButton}
                                onClick={() => setIsOpen(!isOpen)}>
                                {isOpen ?
                                    <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                    :
                                    <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                }
                            </button>
                        ) : null}
                    </div>
                </div>
            </div>
        </section>
    )
}