import React, { useEffect, useState } from "react";
import styles from "./pages.module.css";

import { useBook, useBookUpdate } from "../contexts/BookContext";
import { getBook, getBookAllAccess } from "../api/Api"

import { useParams } from "react-router-dom";
import { BookBanner } from "../comonents/Banners/BookBanner";
import { Footer } from "../comonents/Footer/Footer";

export default function Book() {

    // ----------------------------------------------------- STATE AND HOOKS

    const bookID = useParams().bookID;
    let token = localStorage.getItem("token");

    const book = useBook();
    const bookUpdater = useBookUpdate();

    const [badBook, setBadBook] = useState(false);

    useEffect(() => {
        fetchBook();
        setBadBook(false);
    }, [bookID]);

    async function fetchBook() {
        // gets book
        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBookAllAccess(bookID, token)
                .then((foundBook) => {
                    bookUpdater(foundBook);
                })
                .catch((error) => {
                    console.log("failed to get book");
                    setBadBook(true);
                    console.log(error);
                });
        } else {
            // limited access
            await getBook(bookID, token)
                .then((foundBook) => {
                    bookUpdater(foundBook);
                })
                .catch((error) => {
                    console.log("failed to get book");
                    setBadBook(true);
                    console.log(error);
                });
        }
    }

    // ----------------------------------------------------- RENDER
    if (badBook) {
        return (
            <div className={styles.container}>
                <div className={styles.errorMessage}>
                    This shelf is empty
                </div>
            </div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <BookBanner />
                </div>
                <Footer />
            </div>
        );
    }
}