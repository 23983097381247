import React, { useEffect, useState } from "react";

import Markdown from 'react-markdown';
import Textarea from 'rc-textarea';

import styles from "./Entry.module.css";

import { deleteEntry, patchEntry } from "../../api/Api";
import { useBook } from "../../contexts/BookContext";


export const Entry = (props) => {

    // props:
    //
    // entryItem - object containing entry information
    // setEdited - state setter for edited entry
    // setDeleted - state stter for deleted entry

    // ----------------------------------------------------- STATE AND HOOKS

    const bookItem = useBook();
    let token = localStorage.getItem("token");

    const [prettyDateTime, setPrettyDateTime] = useState();
    const [prettyBody, setPrettyBody] = useState();

    const [isEditing, setIsEditing] = useState(false); // if entry is being edtied

    const [editingPage, setEditingPage] = useState(); // editing values
    const [editingTitle, setEditingTitle] = useState("");
    const [editingBody, setEditingBody] = useState("");
    const [editingReading, setEditingReading] = useState();

    useEffect(() => {
        if (props.entryItem) {
            setPrettyDateTime(prettyDate());
            formatBody(props.entryItem.body);

            setEditingPage(props.entryItem.page);
            setEditingTitle(props.entryItem.title);
            setEditingBody(props.entryItem.body);

            // finds reading numer
            if (props.entryItem && props.entryItem.readingID !== null && bookItem) {
                for (let i = 0; i < bookItem.readings.length; i++) {
                    if (bookItem.readings[i].id === props.entryItem.readingID.id) {
                        // finds reading in book's list of readings
                        setEditingReading(i + 1);
                    }
                }
            }
        }
    }, [props.entryItem])

    // ----------------------------------------------------- PRE-RENDER

    async function sendEdits() {
        // sends edits to the back
        let entryPostModel = {};

        if (Number(editingReading) === 0) { 
            // sets readingID to null if 0 was selected
            console.log("in the if")
            entryPostModel = {
                title: editingTitle,
                body: editingBody,
                page: editingPage,
                readingID: 0
            }
        } else { // defualt
            entryPostModel = {
                title: editingTitle,
                body: editingBody,
                page: editingPage,
                readingID: bookItem.readings[(Number(editingReading)-1)].id
            }
        }

        await patchEntry(entryPostModel, props.entryItem.id, token)
            .then((entry) => {
                setIsEditing(false); // turns off edit mode
                props.setEdited(entry); // sends updated entry back to journal.js
            })
    }

    async function removeEntryFromDb() {
        // removes entry from db and sends info back to journal.js
        await deleteEntry(props.entryItem.id, token)
            .then(() => {
                props.setDeleted(props.entryItem.id);
            })
    }

    const changePage = (event) => {
        // catches changes to page number
        setEditingPage(event.target.value);
    }

    const changeTitle = (event) => {
        // catches changes to title input
        setEditingTitle(event.target.value)
    }

    const changeBody = (event) => {
        // catches changes to body input
        setEditingBody(event.target.value);
    }

    const changeReading= (event) => {
        // catches changes to reading input
        setEditingReading(event.target.value);
    }

    function prettyDate() {
        // formats date dispaly
        const temp = new Date(props.entryItem.time)
        const converted = new Date(temp.getTime()).toLocaleString('en-us', { hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'long', year: 'numeric' });

        return converted;
    }

    //adding white space to the boyd
    function formatBody(body) {
        let formattedBody = []
        let textPeices = body.split('\n'); //splits review string by newlines
        for (let i = 0; i < textPeices.length; i++) {
            if (!textPeices[i]) { //if empty it means there was a blank line
                formattedBody.push(<div key={i} className={styles.spacer}></div>)
            } else {
                formattedBody.push(<Markdown key={i}>{textPeices[i]}</Markdown>) //adds string segment to array
            }
        }
        setPrettyBody(formattedBody)
    }

    function removeEntry() {
        // props for a confirmation to removes entry

        if (window.confirm("Are you sure you want to delete this journal entry? This cannot be undone.")) {
            removeEntryFromDb(); // deletes entry
        }
    }


    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {isEditing ?
                <div className={styles.editingContainer}>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputTitle}>Title</div>
                        <input className={styles.input} key="title input"
                            type="text"
                            value={editingTitle}
                            onChange={changeTitle} />
                    </div>
                    <div className={styles.pageAndReading}>
                        <div className={styles.inputGroup}>
                            <div className={styles.inputTitle}>Page</div>
                            <input className={styles.input} key="page input"
                                type="number"
                                value={editingPage}
                                onChange={changePage}
                                max={bookItem.numPages} />
                        </div>
                        <div className={styles.inputGroup}>
                            <div className={styles.inputTitle}>Reading</div>
                            <input className={styles.input} key="reading input"
                                type="number"
                                placeholder="Reading number"
                                value={editingReading}
                                onChange={changeReading}
                                max={bookItem.readings.length}
                                min={0} />
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputTitle}>Body</div>
                        <Textarea className={styles.inputBody} key="body input"
                            autoSize={{ minRows: 10 }}
                            type="text"
                            value={editingBody}
                            onChange={changeBody} />
                    </div>
                    <div className={styles.buttonBar}>
                        <div className={styles.cancelAndDelete}>
                            <button type="submit" className={styles.cancelButton} onClick={() => setIsEditing(false)}>Cancel</button>
                            <button type="submit" className={styles.deleteButton} onClick={() => removeEntry()}>Delete</button>
                        </div>
                        <button type="submit" className={styles.submitButton} onClick={() => sendEdits()}>Save</button>
                    </div>
                </div>
                :
                <div>
                    <div className={styles.titleContainer}>
                        <h1 className={styles.title}>{props.entryItem.title}</h1>
                        <button className={styles.editButton} onClick={() => setIsEditing(!isEditing)}><img src="/assets/images/editIcon.svg"
                            className={styles.editButtonImage}
                            alt="Edit Icon" />
                        </button>
                    </div>
                    <div className={styles.time}> {prettyDateTime} </div>
                    <div className={styles.page}> Page {props.entryItem.page} </div>
                    <div className={styles.body}> {prettyBody} </div>
                </div>
            }
        </section>
    )
}