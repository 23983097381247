import React, { useEffect, useState } from "react";
import styles from "./Journal.module.css";

import { getEntriesForBook, postEntry } from "../../api/Api";
import { Entry } from "../Entry/Entry";
import { NewEntry } from "../Entry/NewEntry";
import { EntryReadingHeader } from "../Entry/EntryReadingHeader";
import { useBook } from "../../contexts/BookContext";

export const Journal = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");

    const bookItem = useBook();

    const [entries, setEntries] = useState([]);
    const [entryObjects, setEntryObjects] = useState([]);

    const [isAdding, setIsAdding] = useState(false); // states if new entry is being created
    const [newEntry, setNewEntry] = useState(null); // populated by NewEntry when entry is submitted
    const [editedEntry, setEditedEntry] = useState(null) // populates with updated entry when edited
    const [deletedEntry, setDeletedEntry] = useState(null) // populated with deleted entry id

    useEffect(()=> { // fetches entries once book arrives
        setEntries([]) // clears entries if navigating to book from book
        setEntryObjects([])


        if (bookItem && bookItem.userbookID) {
            fetchEntries();
        } else { 
            // clears entries if search to book not in library from book page
            setEntries([])
            setEntryObjects([])
        }
    },[bookItem])

    useEffect(() => {
        if (newEntry !== null) {
            addEntry();
        }
    },[newEntry])

    useEffect(() => {
        if (editedEntry !== null) {
            fetchEntries();
        }
    }, [editedEntry])

    useEffect(() => {
        if (deletedEntry !== null) {
            deleteEntry();
        }
    })

    // ----------------------------------------------------- PRE-RENDER

    async function fetchEntries() {
        // gets entry from back
        await getEntriesForBook(bookItem.userbookID, token)
            .then((foundEntries)=>{
                setEntries(foundEntries);
                makeEntryObjects(foundEntries);
            })
            .catch((error) => {
                console.log("failed to fetch entries");
                console.log("error",error);
            })
    }

    async function addEntry() {
        // adds entry to back
        let entryPostModel = {
            title: newEntry.title,
            body : newEntry.body,
            time : new Date,
            page : newEntry.page,
            readingID : newEntry.readingID
        }

        await postEntry(entryPostModel, bookItem.userbookID, token)
            .then(() => {
                setNewEntry(null) // clears newEntry
                setIsAdding(false); // closes new post adder
                fetchEntries(); // re-fetches entries for ordering
            })
    }

    function updateEntry(){
        // finds and replaces edited entry, remakes entry objects

        for (let i = 0; i < entries.length; i++) {
            if (entries[i].id == editedEntry.id) {
                entries[i] = editedEntry; // replaces entry
                setEditedEntry(null); // clears editedEntry
                makeEntryObjects(entries); // remakes objects
            }
        }
    }

    function deleteEntry() {
        // removes deleted entry from entries array and remakes objects
        var onlyLiveEntries= entries.filter( (e) => {
            return (e.id !== deletedEntry)
        })
        setEntries(onlyLiveEntries);
        makeEntryObjects(onlyLiveEntries);
        setDeletedEntry(null)
    }

    function makeEntryObjects(foundEntries) {
        // makes entry objects

        var tempEntryObjects = []; 
        var currentReading = 0;
        var seenNull = false;

        for (let i = 0; i < foundEntries.length; i++) {

            // make header object if needed
            if (foundEntries[i].readingID == null) {
                // no reading associated with entry
                if (!seenNull) { // already made null header
                    tempEntryObjects.push(<div key={"header" + i}><EntryReadingHeader readingItem={foundEntries[i].readingID} bookItem={bookItem} /></div>);
                    seenNull = true;
                }
            } else if(foundEntries[i].readingID.id != currentReading) { 
                // reading header has not been made yet
                currentReading = foundEntries[i].readingID.id;
                tempEntryObjects.push(<div key={"header"+i}><EntryReadingHeader readingItem = {foundEntries[i].readingID} bookItem = {bookItem}/></div>);
            }

            // makes entry object
            tempEntryObjects.push(<div key={foundEntries[i].id}><Entry entryItem={foundEntries[i]} setEdited={setEditedEntry} setDeleted={setDeletedEntry} /></div>);
        }
        setEntryObjects(tempEntryObjects); 
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <span><h3 className={styles.headerTitle}>Journal</h3></span>
                <button className={styles.plusButton} onClick={ () => setIsAdding(!isAdding) }><img src="/assets/images/plusIcon.svg" alt="add icon" className={styles.icon}/></button>
            </div>
            <div className={styles.content}>
                {isAdding ? <NewEntry bookItem={bookItem} setNewEntry={setNewEntry} setIsAdding={setIsAdding} /> : null}
                {entryObjects}
            </div>
            
        </section>
    )
}