import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import Markdown from 'react-markdown';
import Clamp from "react-multiline-clamp";

import styles from "./LongBook.module.css";

import { Tag } from "../../Tag/Tag"
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

export const LongBook = (props) => {

    // props:
    //
    // bookItem - object containing book info

    // ----------------------------------------------------- STATE AND HOOK
    const [formattedFull, setformattedFull] = useState("");

    useEffect(() => {
        if (props.bookItem && props.bookItem.review) {
            setformattedFull(formatReview(props.bookItem.review));
        }
    }, [props.bookItem.review])


    // ----------------------------------------------------- PRE-RENDER
    var tags = [];
    const bookPath = "/book/" + props.bookItem.bookID;

    const seriesPath = "/series/" + props.bookItem.series?.id;

    if (props.bookItem && props.bookItem.tags) { // checks that bookItem has propegated before accessing
        if (props.bookItem.tags.length > 4) { // checks number of tags
            for (let i = 0; i < 4; i++) { // only renders first 4
                tags.push(<div key={props.bookItem.tags[i].id}> <Tag tagItem={props.bookItem.tags[i]} /> </div>)
            }
        } else { // renders all when less than 4 total
            tags = props.bookItem.tags.map((tag) =>
                <div key={tag.id}> <Tag tagItem={tag} /> </div>
            )
        }
    }

    function formatReview(review) {
        // adds white space to descritpion
        var formattedReview = [];
        let index = 0;

        let reviewPeices = review.split('\n'); // splits review string by newlines
        for (const element of reviewPeices) {
            index++;
            if (!element) { // if empty it means there was a blank line
                formattedReview.push(<div className={styles.spacer} key={index}></div>)
            } else {
                formattedReview.push(<Markdown key={index}>{element}</Markdown>) //adds string segment to array
            }
        }
        return (formattedReview);
    }

    // ----------------------------------------------------- RENDER

    if (window.screen.width > 500) {
        // DESKTOP
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.bookInfo}>
                        <Cover bookItem={props.bookItem} size={"L"} loc="favoriteBook" />
                        <div>
                            <Link to={bookPath} className={styles.link}><h1 className={styles.bookTitle}>{props.bookItem.title}</h1></Link>
                            {props.bookItem.series != null ?
                                <div>
                                    <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.bookItem.series?.name} #{props.bookItem.seriesNum}</h3></Link>
                                </div> :
                                null}
                            <div className={styles.author}><Author authorsList={props.bookItem.authors} size={window.screen.width > 500 ? 1 : 0} link={true} /></div>
                            <div className={styles.tags}>{tags}</div>
                        </div>
                    </div>
                    
                    <div className={styles.reviewContainer}>
                        <Clamp lines={1}
                            maxLines={100}
                            withTooltip={false}
                            withToggle={true}
                            showMoreElement={({ toggle }) => (
                                <button className={styles.readMoreOrLessButton}
                                    onClick={toggle} >
                                    <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                </button>
                            )}
                            showLessElement={({ toggle }) => (
                                <button className={styles.readMoreOrLessButton}
                                    onClick={toggle} >
                                    <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                </button>
                            )}>
                            <div className={styles.bookReview}>{formattedFull} </div>
                        </Clamp>
                    </div>
                </div>
            </div>
        );
    } else {
        // MOBILE
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.bookInfo}>
                        <Cover bookItem={props.bookItem} size={"M"} loc="favoriteBook" />
                        <div>
                            <Link to={bookPath} className={styles.link}><h1 className={styles.bookTitle}>{props.bookItem.title}</h1></Link>
                            {props.bookItem.series != null ?
                                <div>
                                    <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.bookItem.series?.name} #{props.bookItem.seriesNum}</h3></Link>
                                </div> :
                                null}
                            <div className={styles.author}><Author authorsList={props.bookItem.authors} size={window.screen.width > 500 ? 1 : 0} link={true} /></div>
                        </div>
                    </div>
                    <div className={styles.mobileUserInfo}>
                        <div className={styles.tags}>{tags}</div>
                        <div className={styles.reviewContainer}>
                        <Clamp lines={1} 
                                maxLines={100}
                                withTooltip={false}
                                withToggle={true}
                                showMoreElement = { ({toggle}) => (
                                    <button className={styles.readMoreOrLessButton}
                                            onClick={toggle} >
                                            <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                    </button>
                                )}
                                showLessElement = { ({toggle}) => (
                                    <button className={styles.readMoreOrLessButton}
                                            onClick={toggle} >
                                            <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                    </button>
                                )}>
                            <div className={styles.bookReview}>{formattedFull} </div>
                        </Clamp>
                    </div>
                    </div>
                </div>
            </div>
        );
    }


    
}