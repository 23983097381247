import React, { useEffect, useState } from "react";
import styles from "./pages.module.css";
import { useParams } from "react-router-dom";

import { getUser } from "../api/Api";
import { AccountInfo } from "../comonents/AccountInfo/AccountInfo";
import { Footer } from "../comonents/Footer/Footer";

export const Account = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const userID = useParams().id.split(",")[0];
    let token = localStorage.getItem("token");

    const [userObject, setUserObject] = useState({});

    useEffect(()=> {
        fetchUser();
    },[userID]);

    // ----------------------------------------------------- PRE-RENDER

    async function fetchUser() {
        // gets user from db
        await getUser(userID, token)
            .then((user) => {
                setUserObject(user);
            })
            .catch((error) => {
                console.log("failed to get user")
                console.log(error)
            })
    }

    // ----------------------------------------------------- RENDER
    return (
        <section>
            <div className={styles.content}>
                <AccountInfo userItem={userObject} />
            </div>
            <Footer />
        </section>
    )
}