import React from "react";
import { Link } from "react-router-dom";

import styles from "./SearchAuthor.module.css";

export const SearchAuthor = (props) => {
    // props:
    //
    // authorItem - object containing author information
    // active - bool, indicates if author is selected or not

    // ----------------------------------------------------- PRE-RENDER 

    const authorPath = "/author/" + props.authorItem.id;

    const authorDisplayString = props.authorItem.last + ", " + props.authorItem.first;

    // ----------------------------------------------------- RENDER
    return (
        <Link to={authorPath} className={styles.link}>
            <section className={props.active ? styles.containerActive : styles.container}>
                <div className={styles.content}>
                    <div className={styles.authorInfo}>
                        <h2 className={styles.authorName}>{authorDisplayString}</h2>
                    </div>
                </div>
            </section>
        </Link>
    )
}