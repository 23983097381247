import React, { useEffect, useState } from "react";
import styles from "./OutsideDataBox.module.css";
import { useSearchString } from "../../../contexts/SearchContext";
import { getBookFromOpenLibrary, getBooksFromGoogle } from "../../../api/Api";

import { GoogleBook } from "../../BookTypes/Google/GoogleBook";
import { OpenLibraryBook } from "../../BookTypes/OpenLibrary/OpenLibraryBook";

import { SmallDropdown } from "../../SmallDropdown/SmallDropdown";
import { SmallDropdownItem } from "../../SmallDropdown/SmallDropdownItem";
import { Loader } from "../../Loader/Loader";

export const OutsideDataBox = (props) => {

    // props:
    // 
    // type - which book source to use, OL or GO
    // setOptionBookModel - parent's state var setter to send processed option data back


    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");

    let displayOptions = [
        <SmallDropdownItem key="google" displayValue="Google" onClick={() => handleSouceChange("Google")} />,
        <SmallDropdownItem key="ol" displayValue="Open Library (OFFLINE)"/>
    ]

    const [searchInput, setSearchInput] = useSearchString();

    const [isLoading, setIsLoading] = useState(true);

    const [bookOptionObjects, setBookOptionObjects] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const [selectedSource, setSelectedSource] = useState("Google");


    useEffect(() => {
        // initiates sending of info to OL or Google
        setIsLoading(true);

        if (searchInput !== "") {
            getFromGoogle();

            // IF OL EVER COMES BACK ONLINE
            // if (selectedSource === "OL") {
            //     getFromOL();
            // } else {
            //     getFromGoogle();
            // }

        } else {
            setBookOptionObjects(<h2 className={styles.noOptions}>This shelf is empty</h2>);
            setIsLoading(false);
        }
    }, [selectedSource]);

    useEffect(() => {
        // initiates handeling of option selection

        // IF OL COMES BACK ONLINE EVER
        // if (selectedOption !== null) {
        //     if (selectedSource === "OL") {
        //         console.log("currently offline")
        //     } else {
        //         processGoogleOption();
        //     }
        // }

        if (selectedOption !== null) processGoogleOption();

    },[selectedOption]);

    // ----------------------------------------------------- PRE-RENDER

    async function getFromOL() {
        // sends search string to Open Library

        var processedInput = searchInput.replace(/\s+/g, "+"); // replaces spaces with +

        await getBookFromOpenLibrary(processedInput, token)
            .then((foundjson) => {
                let processedBooks = JSON.parse(foundjson);

                if (processedBooks.docs.length === 0 ) {
                    // NO books found
                    console.log("no books found")
                    setBookOptionObjects(<h2 className={styles.noOptions}>This Shelf Is Empty</h2>)
                } else {
                    makeBookOptionObjects(processedBooks.docs);
                }
            })
            .catch((error) => {
                console.log("failed to get book information from OL");
                console.log(error);
            })
    }

    async function getFromGoogle() {
        // sends search string to google
        
        var processedInput = searchInput.replace(/\s+/g, "+"); // replaces spaces with +

        await getBooksFromGoogle(processedInput, token)
            .then((foundjson) => {
                let processedBooks = JSON.parse(foundjson);
                if (processedBooks.items.length === 0) {
                    setBookOptionObjects(<h2 className={styles.noOptions}>No Books Found</h2>);
                } else {
                    makeBookOptionObjects(processedBooks.items);
                }
            })
            .catch((error) => {
                console.log("failed to get book information from google");
                console.log(error);
            })
    }


    function processGoogleOption() {
        // gets relevent information out of google book
         
        let bookModel = {
            isbn: "",
            title : "",
            authors : [],
            pub : "",
            numPages : "",
            description : "",
            genre : ""
        }

        // isbn:
        var isbn = "";
        if (selectedOption.industryIdentifiers !== null) {
            for (let i = 0; i < selectedOption.industryIdentifiers.length; i++) {
                // gets 13 digit isbn for a book in english
                if (selectedOption.industryIdentifiers[i].type === "ISBN_13" &&
                    (selectedOption.industryIdentifiers[i].identifier.charAt(3) == 0 || selectedOption.industryIdentifiers[i].identifier.charAt(3) == 1)) {
                    isbn = selectedOption.industryIdentifiers[i].identifier;
                }
            }
            bookModel.isbn = isbn;
        }

        // title
        bookModel.title = selectedOption?.title;

        // authors
        bookModel.authors = selectedOption?.authors;

        // description
        bookModel.description = selectedOption?.description;
        
        // pub
        bookModel.pub = selectedOption?.publishedDate;

        // numPages
        bookModel.numPages = selectedOption?.pageCount;

        // genre
        bookModel.genre = selectedOption?.categories;

        // sends info back to parent
        props.setOptionBookModel(bookModel);
    }



    function makeBookOptionObjects(possibleBooks) {
        // makes book objects for results of Open Library search

        let temp = [];
        const numBooks = possibleBooks.length > 6 ? 6 : possibleBooks.length;

        for (let i = 0; i < numBooks; i++) {
            temp.push(<GoogleBook googleBookItem={possibleBooks[i].volumeInfo} setSelectedOption={setSelectedOption} key={i} />)

            // IF OL EVER COMES BACK ONLINE
            // if (selectedSource === "Google") {
            //     temp.push(<GoogleBook googleBookItem={possibleBooks[i].volumeInfo} setSelectedOption={setSelectedOption} key={i} />)
            // } else {
            //     temp.push(<OpenLibraryBook openLibraryItem={possibleBooks[i]} setSelectedOption={setSelectedOption} key={i} />)
            // }
        }
        setIsLoading(false);
        setBookOptionObjects(temp);
    }

    function handleSouceChange(newSource) {
        // changes source of book options
        setSelectedSource(newSource);
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                    <h1 className={styles.headerTitle}>Source: </h1>
                    <SmallDropdown buttonText={selectedSource} content={displayOptions} />
            </div>
            <div className={styles.options}>
                { isLoading ?
                    <div className={styles.loader}><Loader /> </div>
                    :
                    bookOptionObjects
                }
            </div>
        </section>
    )
}