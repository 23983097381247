import React, { useState } from "react";

import { LoginForm } from "../comonents/LoginForms/LoginForm";
import { RegisterForm } from "../comonents/LoginForms/RegesterForm";
import { Footer } from "../comonents/Footer/Footer";

import styles from "../comonents/LoginForms/LoginForm.module.css";


export const Login = (props) => {

    //props:
    //
    //putToken - function to save token to local storage

    // ----------------------------------------------------- STATE AND HOOKS

    const [isLoggingIn, setIsLoggingIn] = useState(true);

    // ----------------------------------------------------- PRE-RENDER

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.content}>
            <img src="/assets/images/logo.png" className={styles.logo}/>
                {
                    isLoggingIn ? (
                        <div>
                            <LoginForm putToken={props.putToken} />
                            <div className={styles.noAccount}>
                                <span>Don't have an account?</span>
                                <span><button className={styles.registerButton} onClick={() => { setIsLoggingIn(false) }}>Register!</button></span>
                            </div>
                        </div>
                    ) : (
                        <div >
                            <div className={styles.sectionHeader}>Register</div>
                            <div className={styles.form}>
                                <div className={styles.registerEnquire}>
                                    Email earmarkedapp@gmail.com to inquire about registering
                                </div>
                            </div>
                            <div className={styles.noAccount}>
                                    <span>Already have an account?</span>
                                    <span><button className={styles.registerButton} onClick={() => { setIsLoggingIn(true) }}>Sign in!</button></span>
                                </div>
                        </div>
                    )
                }
            </div>
            <Footer />
        </section>
    );
}