import React, { useState, useEffect } from "react";

import { getBookPage, getBookPageAllAccess, getBooksAllAccess, getBooks } from '../../api/Api';
import { WatchBook } from '../BookTypes/WatchBook/WatchBook';

import styles from "./WatchList.module.css";

export const WatchList = (props) => {

    // props:
    //
    // page - currently selected page

    // ----------------------------------------------------- STATE AND HOOKS

    const [books, setBooks] = useState([]);
    const [listOfBooks, setListOfBooks] = useState([]);

    let token = localStorage.getItem("token");

    useEffect(() => {
        fetchNumCurrentlyReading();
    }, []);

    useEffect(() => { fetchNumCurrentlyReading() }, [props.page]) //triggers rerender when page changes

    // ----------------------------------------------------- PRE-RENDER


    async function fetchNumCurrentlyReading() {
        // gets the number of books marked currently reading to determin if module has more space downwards

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBooksAllAccess("currentlyReading", token)
                .then((currentlyReadingBooks) => {
                    if (currentlyReadingBooks.length === 0) fetchBooks(1);
                    else fetchBooks(currentlyReadingBooks.length);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    console.log(error)
                });
        } else {
            // limited access
            await getBooks("currentlyReading", token)
                .then((currentlyReadingBooks) => {
                    if (currentlyReadingBooks.length === 0) fetchBooks(1);
                    else fetchBooks(currentlyReadingBooks.length);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    console.log(error)
                });
        }
    }


    async function fetchBooks(numCurrentlyReading) {
        // gets book page from back

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        let pageSize = window.screen.width > 500 ? (3 * numCurrentlyReading) : (2 * numCurrentlyReading);

        if (role === "admin") {
            // all access
            await getBookPageAllAccess(props.page, pageSize, "watch", "pub", false, token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books");
                    makeBookItems([]); // invokes empty shelf message
                    console.log(error);
                });
        } else {
            // limited access
            await getBookPage(props.page, pageSize, "watch","pub", false, token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books");
                    makeBookItems([]); // invokes empty shelf message
                    console.log(error);
                });
        }
    }

    function makeBookItems(foundBooks) {
        //makes list of books
        if (foundBooks.length > 0) {
            let tempList = []
            for (let i = 0; i < foundBooks.length; i++) {
                tempList.push(
                    <div key={foundBooks[i].isbn}><WatchBook bookItem={foundBooks[i]} /></div>
                )
            }
            setListOfBooks(tempList);
        } else {
            setListOfBooks(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }
    }

    // ----------------------------------------------------- RENDER


    return(
        <section className={styles.container}>
            <div className={ styles.content}>
               {listOfBooks}
            </div>
        </section>
    );
}