import React, { useEffect, useState } from 'react';
import { getBookPage, getBookPageAllAccess, getTotalItems, getTotalItemsAllAccess } from '../../api/Api';

import styles from './FavoriteBooks.module.css';
import { LongBook } from '../BookTypes/LongBook/LongBook';
import { PageSelector } from '../PageSelector/PageSelector';

export const FavoriteBooks = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [books, setBooks] = useState([]);
    const [listOfBooks, setListOfBooks] = useState([]);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(4);
    const [totalPages, setTotalPages] = useState();

    let token = localStorage.getItem("token");

    useEffect(() => {
        fetchBooks();
        fetchPageTotal();
    }, []);

    //useEffect(() => { makeBookItems(); }, [books]); //gets book cards when books arrive from back
    useEffect(() => { fetchBooks() }, [page]) //triggers rerender when page changes


    // ----------------------------------------------------- PRE-RENDER

    async function fetchBooks() {
        // gets page of favorite books

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBookPageAllAccess(page, pageSize, "favorite", "bookModel.authors.last", true, token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    makeBookItems([]); // invoke empty shelf message
                    console.log(error)
                });
        } else {
            // limited access
            await getBookPage(page, pageSize, "favorite","bookModel.authors.last", true, token)
                .then((foundBooks) => {
                    setBooks(foundBooks);
                    makeBookItems(foundBooks);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    makeBookItems([]); // invokes empty shelf message
                    console.log(error)
                });
        }

    }

    async function fetchPageTotal() {
        //gets the total number of books for a given library from back and calculates total number of pages

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getTotalItemsAllAccess("favorite", token)
                .then((itemCount) => {
                    setTotalPages(Math.ceil(itemCount / pageSize));
                })
                .catch((error) => {
                    console.log("failed to get total number of pages");
                    console.log(error)
                })
        } else {
            // limited access
            await getTotalItems("favorite", token)
                .then((itemCount) => {
                    setTotalPages(Math.ceil(itemCount / pageSize));
                })
                .catch((error) => {
                    console.log("failed to get total number of pages");
                    console.log(error)
                })
        }

    }

    function makeBookItems(foundBooks) {
        //makes list of book items
        if (foundBooks.length > 0) {
            let tempList = foundBooks.map((book) =>
                <div className={styles.book} key={book.isbn}><LongBook bookItem={book} /></div>)
            setListOfBooks(tempList);
        } else {
            setListOfBooks(
                <div className={styles.emptyShelf}>
                    <h1>This shelf is currently empty</h1>
                </div>
            )
        }
    }

    function handleClick(newPage) {
        // handles click to new button page
        setPage(newPage);
    }

    
    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.sectionTitle}>Favorites</h1>
                <div className={styles.pageSelector}>
                    { totalPages > 1 ? <PageSelector totalPages={totalPages} switchPage={handleClick} selectedPage={page} /> : null}
                </div>
            </div>
            <div className={styles.content} id="favorites">
                <div className={styles.gridContainer}>
                    {listOfBooks}
                </div>
            </div>
        </div>
    );
}