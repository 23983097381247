import React, { useEffect, useState } from "react";
import styles from "./AddBookForm.module.css";

import { addAuthor, addBook, addSeries, getFromSearch, getGenres, postBookCover } from "../../api/Api";

import { useSearchString } from "../../contexts/SearchContext";

import { SeriesSuggestion } from "../SuggestionTypes/SeriesSuggestion/SeriesSuggestion";
import { AuthorSuggestion } from "../SuggestionTypes/AuthorSuggestion/AuthorSuggestion";
import { useNavigate } from "react-router-dom";
import { OutsideDataBox } from "./OutsideDataBox/OutsideDataBox";
import { SingleTextInput } from "./SingleTextInput/SingleTextInput";


export const AddBookForm = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");
    // determining access level
    let tokenPeices = token.split(".");
    let idAndRole = atob(tokenPeices[1]);
    let role = idAndRole.split(",")[1];

    const navigate = useNavigate();

    let timeOfBackendSearch = Date.now();
    let timeOfAPISearch = Date.now();

    const [suggestionObjects, setSuggestionObjects] = useState([]);

    const [file, setFile] = useState();

    const [searchInput, setSearchInput] = useSearchString();

    const [isAdding, setIsAdding] = useState(false); // if book is being added to db

    const [genreOptionObjects, setGenreOptionObjects] = useState([]);
    const [genres, setGenres] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null); // book that was clicked on 

    const [optionBookModel, setOptionBookModel] = useState({}); // book model from selected Google or OL

    // states to catch changes to input feilds
    const [editingISBN, setEditingISBN]= useState("");
    const [editingTitle, setEditingTitle]= useState("");
    const [editingSeriesNum, setEditingSeriesNum]= useState("");
    const [editingNumPages, setEditingNumPages]= useState("");
    const [editingPub, setEditingPub]= useState("")
    const [editingDescription, setEditingDescription]= useState("");

    const [editingSeriesName, setEditingSeriesName] = useState("");
    const [editingAuthor, setEditingAuthor] = useState("");

    const [isRestricted, setIsRestricted] = useState(false);

    const [selectedSeries, setSelectedSeries] = useState(null);
    const [selectedOtherAuthors, setSelectedOtherAuthors] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState([]);

    const [duplicateSeriesNum, setDuplicateSeriesNum] = useState(false);
    const [badSubmit, setBadSubmit] = useState(false);

    useEffect(() => {
        // researches api(s) if search string changes

        let currentTime = Date.now();
        if ((currentTime - timeOfAPISearch) / 10 >= 0.2 && searchInput !== "") {
            timeOfAPISearch = Date.now();

            // resets selected option
            setSelectedOption(null);
            setEditingISBN("");
            setEditingTitle("");
            setEditingNumPages("");
            setEditingDescription("");
            setEditingSeriesNum("");
            setEditingPub("");  
            setEditingSeriesName("");

        }
    }, [searchInput])
    

    useEffect(() => {
        // runs on first render to get genre objects

        fetchGenres();

    },[])

    useEffect(() => {
        // remakes genre option obejcts when one is selected/unselected

        if (genres.length !== 0) makeGenreObjects(genres)

    },[selectedGenre])

    useEffect(() => {
        // rescives changes from outside data box

        setEditingISBN(optionBookModel.isbn);
        setEditingTitle(optionBookModel.title);
        setEditingDescription(optionBookModel.description);
        setEditingPub(optionBookModel.pub);
        setEditingNumPages(optionBookModel.numPages);

    }, [optionBookModel])


    // ----------------------------------------------------- PRE-RENDER

    async function fetchGenres() {
        // gets genre objects from back

        await getGenres(token)
            .then((foundGenres) =>{
                setGenres(foundGenres);
                makeGenreObjects(foundGenres);
            })
            .catch((error) =>{
                console.log("failed to fetch genres");
                console.log(error)
            })
    }

    async function searchForSeries() {
        // searches existing series for possible match

        let searchObj = {
            searchString: editingSeriesName,
            searchType: "Series"
        }

        await getFromSearch(searchObj, token)
            .then((foundSeries) => {
                const temp = []
                for (let i = 0; i < foundSeries.length; i++) {
                    temp.push(<div className={styles.suggestion} key={i}><SeriesSuggestion seriesItem={foundSeries[i]} setSelectedSeries={setSelectedSeries} /></div>)
                }
                setSuggestionObjects(temp)
            })
            .catch((error) => {
                console.log("failed to search for series");
                console.log(error)
            })
    }

    async function searchForAuthor(makeSuggestions, authorString, saveTo) {
        // searches existing authors for possible match

        let searchObj = {
            searchString: authorString,
            searchType: "Author"
        }

        await getFromSearch(searchObj, token)
            .then((foundAuthors) => {
                if (makeSuggestions) {
                    // if called to make suggestions
                    const temp = [];
                    for (let i = 0; i < foundAuthors.length; i++) {
                        temp.push(<div className={styles.suggestion} key={i}><AuthorSuggestion authorItem={foundAuthors[i]} setSelectedAuthor={saveTo} /></div>)
                    }
                    setSuggestionObjects(temp);
                } else if (!makeSuggestions && foundAuthors.length === 1) {
                    // if called to search selected option provided string and only one result is found
                    saveTo(foundAuthors[0]);
                } else if (!makeSuggestions) {
                    // more than 1 or 0 results found from processing book selection
                    setEditingAuthor(authorString)
                }
            })
            .catch((error) => {
                console.log("failed to search for authors");
                console.log(error);
            })
    }

    async function postOtherAuthorsToDB() {
        // adds supporting authors

        var authors = [];
        for (let i = 0; i < selectedOtherAuthors.length; i++) {
            // goes through each author field

            if (selectedOtherAuthors[i].id === null) {
                // author not from suggestions
                let postModel = {
                    name: selectedOtherAuthors[i].name,
                    first: selectedOtherAuthors[i].first,
                    last: selectedOtherAuthors[i].last
                }

                await addAuthor(postModel, token)
                    .then((postedAuthor) => {
                        authors.push(postedAuthor)
                    })
                    .catch((error) => {
                        console.log("failed to post author")
                        console.log(error)
                    })
            }
            else {
                // otherwise add selected author
                authors.push(selectedOtherAuthors[i]);
            }
        }

        if (editingAuthor !== "") {
            // author name in input feild, + was not clicked, and suggestion not selected
            let splitName = editingAuthor.split(" ")
            let postModel = {
                name: editingAuthor,
                first: splitName.slice(0, -1).join(" "),
                last: splitName.slice(-1).join(" ")
            }

            await addAuthor(postModel, token)
                .then((postedAuthor) => {
                    authors.push(postedAuthor)
                })
                .catch((error) => {
                    console.log("failed to post author")
                    console.log(error)
                })
        }
        return authors;
    }

    async function postSeriesToDB() {
        // adds series to db if no selected series but a name has been input

        if (!selectedSeries && editingSeriesName !== "") {
            return await addSeries(editingSeriesName, token)
                .then((postedSeries) => {
                    return postedSeries;
                })
                .catch((error) => {
                    console.log("failed to post series")
                    console.log(error)
                })
        } else {
            return null;
        }
    }

    async function postBookToDB(authors, series) {
        // adds book to db

        let bookPostModel = {
            isbn: editingISBN,
            title: editingTitle,
            authors: [],
            seriesID: null,
            seriesNum: editingSeriesNum,
            pub: new Date(editingPub),
            cover: "https://covers.openlibrary.org/b/isbn/" + editingISBN + "-M.jpg",
            numPages: editingNumPages,
            description: editingDescription,
            restricted: isRestricted,
            genreIDs: []
        }

        for (let i = 0; i < authors.length; i++) {
            // gets author ids
            bookPostModel.authors.push(authors[i].id);
        }

        if (series !== null) {
            // new series posted
            bookPostModel.seriesID = series.id;

        } else if (selectedSeries !== null) {
            // existing series selected
            bookPostModel.seriesID = selectedSeries.id;
        }

        if (file !== undefined) {
            bookPostModel.cover = "aws";
        }
        else {
            // adds spesific book cover if sourse is OL
            bookPostModel.cover = `https://covers.openlibrary.org/b/id/${selectedOption.cover_i}-M.jpg`;
        }

        if (selectedGenre.length !== 0) {
            // adds selected genres
            let temp = selectedGenre.map((g) => g.genreID);
            bookPostModel.genreIDs = temp;
        }

        await addBook(bookPostModel, token)
            .then((postedBook) => {
                navigate(`/book/${postedBook.bookID}`);
            })
            .catch((error) => {
                console.log("failed to post book to database");
                console.log(error);
                setIsAdding(false);
            })
    }

    async function postCoverToAWS() {
        // adds cover to aws

        if (file !== null) {
            let newName = editingISBN + ".jpg";
            let formData = new FormData();
            formData.append(`file`, file, newName);

            await postBookCover(editingISBN, file, token)
                .then(() => {
                    return true;
                })
                .catch((error) => {
                    console.log("failed to add cover");
                    console.log(error);

                    setIsAdding(false);
                })
            return true;
        }
    }

    async function handleSubmit() {
        // post author and series, if necessary, then posts book

        if (duplicateSeriesNum ) {
            setBadSubmit(true)
        } else {
            setBadSubmit(false);
            setIsAdding(true);

            const [authors, series] = await Promise.all([
                postOtherAuthorsToDB(),
                postSeriesToDB(),
                postCoverToAWS()
            ]);
            postBookToDB(authors, series);
        }
    }

    function makeGenreObjects(foundGenres) {
        // makes genre objects

        let temp = [];
        for (let i = 0; i < foundGenres.length; i++) {
            // console.log(selectedGenre, selectedGenre.includes(foundGenres[i]), foundGenres[i])
            temp.push(<button className={selectedGenre?.includes(foundGenres[i]) ? styles.activeGenre : styles.inactiveGenre} 
                                key={i} 
                                onClick={ () => handleGenreClick(foundGenres[i])}> 
                                {foundGenres[i].genreNAME}
                        </button>)
        }
        setGenreOptionObjects(temp);
    }

    // ------------------------------------------ STATE VAR CHANGE CATCHERS && RELATED FUNCTIONS

    const handleGenreClick = (genre) => {
        // handles selection or unselection of genre

        if (selectedGenre.includes(genre)) {
            // unselect
            let temp = [];
            for (let i=0; i < selectedGenre.length; i++) {
                if (genre !== selectedGenre[i]) temp.push(selectedGenre[i]);
            }
            setSelectedGenre(temp);
        } else {
            setSelectedGenre(selectedGenre => [...selectedGenre, genre]); 
        }
    }

    const handleChangeIsbn = (value) => {
        // catchest changes to isbn 

        setEditingISBN(value);
        setSuggestionObjects(null);
    }

    const handleChangeTitle = (value) => {
        // catches changes to title

        setEditingTitle(value);
        setSuggestionObjects(null);
    }

    const handleChangeAuthor = (event) => {
        // catches changes to author

        setEditingAuthor(event.target.value);
        setSuggestionObjects(null);

        // searches for existing authors if enough time has elapsed from last search
        let currentTime = Date.now();
        if ((currentTime - timeOfBackendSearch) / 1000 >= 0.2) {
            timeOfBackendSearch = Date.now();
            searchForAuthor(true, event.target.value, handleSelectingOtherAuthor);
        }
    }

    function handleRemoveOtherAuthorSelection(removedIndex) {
        // removes selected author

        let temp = []
        for (let i = 0; i<selectedOtherAuthors.length; i++) {
            if (i !== removedIndex) {
                temp.push(selectedOtherAuthors[i]);
            }
        }
        setSelectedOtherAuthors(temp);
    }

    function handleSelectingOtherAuthor(author) {
        // selects author from authorSuggestion

        setSelectedOtherAuthors(selectedOtherAuthors => [...selectedOtherAuthors, author]);
        setEditingAuthor("");
    }

    function handleAddUnknownAuthor() {
        // adds unselected author

        if (editingAuthor !== "") {
            let splitName = editingAuthor.split(" ");
            let authorItem = {
                id: null,
                name: editingAuthor,
                first: splitName.slice(0, -1).join(" "),
                last: splitName.slice(-1).join(" "),
                booksByAuthor: []
            }

            setSelectedOtherAuthors(selectedOtherAuthors => [...selectedOtherAuthors, authorItem]);
            setEditingAuthor("");
        }
    }

    const handleChangeSeriesName = (event) => {
        // catches changes to series name
        setEditingSeriesName(event.target.value);

        // searches for existing series if enough time has elapsed from last search
        let currentTime = Date.now();
        if ((currentTime - timeOfBackendSearch) / 1000 >= 0.2) {
            timeOfBackendSearch = Date.now();
            searchForSeries();
        }
    }

    function handleRemoveSeriesSelection() {
        // removes series selection 
        setSelectedSeries(null);
    }

    const handleChangeSeriesNum = (value) => {
        // catches changes to series number

        setEditingSeriesNum(value);
        setSuggestionObjects(null);

        if (selectedSeries && selectedSeries.booksInSeries !== null) {
            // gets existing numbers from series
            let existingNumbers = []
            selectedSeries.booksInSeries.map((book) => {
                existingNumbers.push(book.seriesNum)
            })
            // checks that entered number has not already been used
            if (existingNumbers.includes(value)) {
                setDuplicateSeriesNum(true);
            }
            else {
                setDuplicateSeriesNum(false);
            }
        }
    }

    const handleChangeNumPages = (value) => {
        // catches changes to number of pages

        // setExistingInfoSearchResults(null);
        setEditingNumPages(value);
    }

    const handleChangePub = (value) => {
        // catches changes to pub date
        
        setEditingPub(value);
        setSuggestionObjects(null);
    }

    const handleChangeDescription = (value) => {
        // catches changes to description

        setEditingDescription(value);
        setSuggestionObjects(null);
    }

    const handleChangeCover = (event) => {
        // catches changes to cover image

        setFile(event.target.files[0])
    }

    const handleChangeRestricted = () => {
        // catches changes to restricted checkbox

        setIsRestricted(!isRestricted)
    }


    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div>
                <OutsideDataBox setOptionBookModel={setOptionBookModel}/>
            </div>
            <h2 className={styles.header}>Add your own info</h2>
            <div className={styles.inputFeilds}>

                <div className={styles.textFields}>
                    <SingleTextInput label="Isbn:" setValue={handleChangeIsbn} foundValue={optionBookModel?.isbn} size="S"/>
    
                    <SingleTextInput label="Title:" setValue={handleChangeTitle} foundValue={optionBookModel?.title} size="S"/>

                    <div>
                        <h1 className={styles.label}>Author(s): </h1>
                        {selectedOtherAuthors.map((author, index) =>
                            <div className={styles.selection} key={"selected author" + index}>
                                <AuthorSuggestion authorItem={author} />
                                <img className={styles.removeSelection} onClick={() => handleRemoveOtherAuthorSelection(index)} alt="remove button"/>
                            </div>
                        )}

                        <div className={styles.authorAndAdd}>
                            <input type="text" key="authorInput" className={styles.shortInput} value={editingAuthor} onChange={handleChangeAuthor} />
                            <button className={styles.addButton} onClick={handleAddUnknownAuthor} alt="add button"> + </button>
                        </div>
                    </div>

                    <div>
                        <h1 className={styles.label}>Series: </h1>
                        {selectedSeries ?
                            <div className={styles.selection} key="selected series"><SeriesSuggestion seriesItem={selectedSeries} /> <img className={styles.removeSelection} onClick={handleRemoveSeriesSelection} alt="remove button"/></div>
                            :
                            <input type="text" className={styles.input} value={editingSeriesName} onChange={handleChangeSeriesName} />}
                    </div>

                    <div>
                        <SingleTextInput label="Series Number:" setValue={handleChangeSeriesNum} size="S"/>
                        {duplicateSeriesNum ? <h5 className={styles.duplicateSeriesNum}>There is already a book in that slot</h5> : null}
                        {isNaN(editingSeriesNum) ? <h5 className={styles.duplicateSeriesNum}>Series number must be a valid number</h5> : null}
                        {/* {editingSeriesName !== "" && editingSeriesNum === "" ? <h5 className={styles.duplicateSeriesNum}>You've selected a series but not included the number</h5> : null} */}
                    </div>

                    <SingleTextInput label="Number of Pages:" setValue={handleChangeNumPages} foundValue={optionBookModel?.numPages} size="S"/>

                    <SingleTextInput label="Publication Date:" setValue={handleChangePub} foundValue={optionBookModel?.pub} size="S"/>

                    <SingleTextInput label="Description:" setValue={handleChangeDescription} foundValue={optionBookModel?.description} size="L"/>

                    <div>
                        <h1 className={styles.label}>Genre: </h1>
                        <h5 className={styles.label}>Select all that apply</h5>
                        <div className={styles.genreContainer}>
                            {genreOptionObjects}
                        </div>
                    </div>

                    <div className={styles.cover}>
                        <h1 className={styles.label}>Cover: </h1>
                        <input id="input" type="file" accept=".jpg" className={styles.fileSelector} onChange={handleChangeCover}/>
                    </div>

                    {
                        role === "admin" ?
                        <div>
                            <input type="checkbox" id="restricted" name="restricted" onChange={handleChangeRestricted}/>
                            <label htmlFor="restricted" className={styles.labelCheck}> Restricted</label>
                        </div>
                        :
                        null
                    }

                    <div>
                        {badSubmit ? <h5 className={styles.duplicateSeriesNum}>Something doesn't look quite right</h5> : null}
                        {isAdding ?
                            <div className={styles.loadingGif}><img src="/assets/images/loading.gif" alt="gif of a book flipping pages" /></div>
                            :
                            <button className={badSubmit ? styles.badSubmitButton : styles.submitButton} onClick={handleSubmit}>Submit Book</button>
                        }
                    </div>

                </div>
                <div className={styles.suggestions}>
                    {suggestionObjects}
                </div>
            </div>
        </section>
    );
}