import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';

import ProgressBar from "@ramonak/react-progress-bar";

import { endReading, patchBook, postReading } from '../../../api/Api';

import styles from "./CurrentlyReadingBook.module.css"

import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

export const CurrentlyReadingBook = (props) => {

    // props:
    //
    // bookItem - object containing book info

    // ----------------------------------------------------- STATE AND HOOKS

    let token = localStorage.getItem("token");

    const [isDisplay, setIsDisplay] = useState(false); //Display edit box?

    const [pageItirator, setPageItirator] = useState(props.bookItem.progress); //update current page
    const [renderProgress, setRenderProgress] = useState(props.bookItem.progress); //triggers rerender when progress saved

    let progressValue = Math.round((renderProgress / props.bookItem.numPages) * 100); // recalculates when renderProgress changes

    const [progressCheck, setProgressCheck] = useState(); //Where to place progress bar lable

    const bookPath = "/book/" + props.bookItem.bookID;
    const seriesPath = "/series/" + props.bookItem.series?.id;

    useEffect(() => {
        if (window.screen.width > 500) {
            // DESKTOP
            setProgressCheck(progressValue > 20);
        } else {
            // MOBILE
            setProgressCheck(progressValue > 30);
        }
    },[])

    // ----------------------------------------------------- LOGIC

    const itiratePage = (event) => {
        //catches changes to page number
        setPageItirator(event.target.value);
    }

    const pagesUpdatesObj = {
        //collects data to be sent to backend
        progress: parseInt(pageItirator)
    }

    async function savePageChanges() {
        //sends updated data to backend
        if (pageItirator > props.bookItem.numPages ) { //sets to max pages if new page num is larger than pages in book
            updateBookProgress( {progress: props.bookItem.numPages} )
            setPageItirator(props.bookItem.numPages)

        } else if (pageItirator < 0 ) { //sets to 0 if new page num is less than 0
            updateBookProgress( {progress: 0} )
            setPageItirator(0)
            
        } else { //sets new page num
            updateBookProgress( pagesUpdatesObj)
        }

        if (window.screen.width > 500) {
            // DESKTOP
            setProgressCheck(Math.round((pageItirator / props.bookItem.numPages) * 100) > 20);
        } else {
            // MOBILE
            setProgressCheck(Math.round((pageItirator / props.bookItem.numPages) * 100) > 30);
        }
    }

    async function updateBookProgress(updateValue) {

        await patchBook(props.bookItem.userbookID, updateValue, token)
            .then((updatedBook) => {
                setRenderProgress(updatedBook.progress);
            })
            .catch((error) => {
                console.log("failed to update reading progress")
                console.log(error)
            })
    }

    async function changeStatus(newStatus) {
        //sends status update to backend
        await patchBook(props.bookItem.userbookID, { status: newStatus }, token)
            .then(() => {
                props.reload()
        })

    }

    async function finishedReading() {
        //adds reading to db when book is finished and clears reading start date

        const readingEndModel = {
            end : new Date,
            bookStatus : "read"
        }

        await endReading(props.bookItem.currentReading.id, readingEndModel, token);

    }

    const handleKeyDown = (e) => {
        //sends page to database if 'enter' is pressed
        if (e.keyCode === 13) {
            savePageChanges();
        }
    }

    // ----------------------------------------------------- RENDER


    return (
        <div className={styles.currentBook} >
            <Cover bookItem = {props.bookItem} size = {window.screen.width > 500 ? "L" : "M"} loc="currentlyReading"/>
            <div>
                <Link to={bookPath} className={styles.link}><h1 className={styles.bookTitle}>{props.bookItem.title}</h1></Link>
                {props.bookItem.series != null ?
                    <div>
                        <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.bookItem.series?.name} #{props.bookItem.seriesNum}</h3></Link>
                    </div> :
                    null}
                <Author authorsList={props.bookItem.authors} size={1} link={true}/>
                <div className={styles.progressContainer}>
                    {progressCheck ? (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#7184AE"
                            labelColor="#fff"
                            labelAlignment="right"
                        />
                    ) : (
                        <ProgressBar
                            className={styles.progressBar}
                            completed={progressValue}
                            bgColor="#7184AE"
                            labelColor="#7184AE"
                            labelAlignment="outside"
                        />
                    )}
                    <button
                        className={styles.editButton}
                        onClick={() => setIsDisplay(!isDisplay)}>
                        <img src="assets/images/editIcon.svg"
                            className={styles.editButtonImage}
                            alt="Edit Icon" 
                        />
                    </button>
                    <div className={styles.editBox}>
                        {isDisplay ? (
                            <div className={styles.editContainer}>
                                <div className={styles.numberAndSave}>
                                    <input name="Page"
                                        value={pageItirator}
                                        onChange={itiratePage}
                                        placeholder={props.bookItem.progress}
                                        inputMode="numeric"
                                        type="number"
                                        max={props.bookItem.numPages}
                                        className={styles.inputBox}
                                        onKeyDown={handleKeyDown}
                                    />
                                    <button className={styles.saveButton}
                                        type="submit"
                                        onClick={savePageChanges}
                                    >Save
                                    </button>
                                </div>
                                <div className={styles.markButtons}>
                                    <button className={styles.markButton} onClick={() => {
                                        changeStatus("read");
                                        finishedReading();
                                    }}
                                    >Mark Done</button>
                                    <button className={styles.markButton} onClick={() => {
                                        changeStatus("DNF");
                                    }}
                                    >Mark DNF</button>
                                </div>
                            </div>
                        ) 
                        : 
                        (
                            null
                        )}
                    </div>
                </div>

            </div>
        </div>
    );

}