import React, { useEffect, useState } from 'react';
import styles from "./HomeTBR.module.css";

import { getBooks, getBooksAllAccess } from '../../api/Api';

import { TBRbook } from '../BookTypes/TBRBook/TBRBook';
import { Loader } from "../Loader/Loader";

export const HomeTBR = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [TBRBooks, setTBRBooks] = useState([]);
    const [listOfBooks, setListOfBooks] = useState([]);

    const [numCurrentlyReaing, setNumCurrentlyReading] = useState();
    const [infoFound, setInfoFound] = useState(false);

    const [isLoading, setIsLoading] = useState(true);

    let token = localStorage.getItem("token");

    useEffect(() => { // gets books and pages on first render
        getInfo();
    }, []);

    useEffect(() => { // makes books once all data is found
        if (TBRBooks.length > 0) { // only make display if there are TBR books
            getRandomDisplay();
        }
    },[infoFound]) 


    // ----------------------------------------------------- PRE-RENDER

    async function getInfo() {
        setIsLoading(true);
        const [] = await Promise.all([
            fetchBooks(),
            fetchNumCurrentlyReading()
        ]);
        setInfoFound(true);    
    }

    async function fetchBooks() {
        // gets books from back

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBooksAllAccess("TBR", token)
                .then((foundBooks) => {
                    if (foundBooks.length === 0) {
                        // no books empty message
                        setListOfBooks(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
                    } else {
                        setTBRBooks(foundBooks);
                    }
                })
                .catch((error) => {
                    console.log("failed to get books")
                    setListOfBooks(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
                    console.log(error)
                });
        } else {
            // limited access
            await getBooks("TBR", token)
                .then((foundBooks) => {
                    if (foundBooks.length === 0) {
                        console.log("no fund books", foundBooks)
                        // no books empty message
                        setListOfBooks(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
                    } else {
                        setTBRBooks(foundBooks);
                    }
                })
                .catch((error) => {
                    console.log("failed to get books")
                    setListOfBooks(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
                    console.log(error)
                });
        }
    }

    async function fetchNumCurrentlyReading() {
        // gets the number of books marked currently reading to determin if module has more space downwards
        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        if (role === "admin") {
            // all access
            await getBooksAllAccess("currentlyReading", token)
                .then((currentlyReadingBooks) => {
                    setNumCurrentlyReading(currentlyReadingBooks.length);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    console.log(error)
                });
        } else {
            // limited access
            await getBooks("currentlyReading", token)
                .then((currentlyReadingBooks) => {
                    setNumCurrentlyReading(currentlyReadingBooks.length);
                })
                .catch((error) => {
                    console.log("failed to get books")
                    console.log(error)
                });
        }
    }

    function getRandomDisplay() {
        // gets random indexes and corrosponding books
        // gets number of books to show, 3 if 0 or 1 currently reading 3*n otherwise

        var numBooks; // number of books to show
        var windowModifiyer = window.screen.width > 500 ? 3 : 2; // number of books across based on window size

        // gets num books
        if (numCurrentlyReaing === 0) numBooks = windowModifiyer;
        else if (numCurrentlyReaing >= 1) numBooks = windowModifiyer * numCurrentlyReaing;

        // checks that there are enough books
        if (TBRBooks.length <= numBooks) {
            makeBookItems(TBRBooks);
            return;
        }

        // makes random display
        var indexes = [];
        var tempDisplay = []

        //if currenly reading * 3 would be more than a page of books
        if (numCurrentlyReaing > 6) {
            makeBookItems(TBRBooks); 
        } else {
            //gets number of random indexes for each currenly reading book
            while (indexes.length < numBooks) {
                var r = Math.floor(Math.random() * TBRBooks.length - 1) + 1;
                if (indexes.indexOf(r) === -1) {
                    indexes.push(r);
                    tempDisplay.push(TBRBooks[r]);
                }
            }
            makeBookItems(tempDisplay); 
        }
    }

    function makeBookItems(foundBooks) {
        //makes list of books

        if (foundBooks.length > 0) {
            let tempList = []
            for (let i = 0; i < foundBooks.length; i++) {
                tempList.push(
                    <div key={foundBooks[i].isbn}><TBRbook bookItem={foundBooks[i]} /></div>
                )
            }
            setListOfBooks(tempList);
        } else if (infoFound === true) {
            setListOfBooks(<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }

        setIsLoading(false)
    }

    // ----------------------------------------------------- RENDER
    return (
        <div className={styles.container}>
            {isLoading ?
                <div className={styles.loader}>
                    <Loader />
                </div>
                :
                <div className={styles.content}>
                    {listOfBooks}
                </div>
            }
        </div>

    );

}