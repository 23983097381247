import React from "react";

import styles from "./EditionBox.module.css";
import { useBook } from "../../contexts/BookContext";

export const EditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const bookItem = useBook();

    // ----------------------------------------------------- PRE-RENDER
    
    //formats date
    let date = "";
    if (bookItem && bookItem.pub) {  
        const temp = new Date(bookItem.pub)
        date = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' });
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <h4 className={styles.header}>ISBN:</h4>
            <div className={styles.value}>{bookItem.isbn}</div>

            <h4 className={styles.header}>Pages:</h4>
            <div className={styles.value}>{bookItem.numPages}</div>

            <h4 className={styles.header}>First Published:</h4>
            <div className={styles.value}>{date}</div>

            {
                bookItem.genres.length > 0 ?
                    <div>
                        <h4 className={styles.header}>Genre:</h4>
                        <div className={styles.genreList}>{bookItem.genres.map((g) => <div className={styles.genre} key={g.genreID}>{g.genreNAME}</div>)}</div>
                    </div>
                    :
                    null
            }
        </div>
    );
}